.main {

    .bottom-tips {
        max-width: 100vw;
        width: 100%;

        z-index: 2;
        position: fixed;
        bottom: 20px;

        display: flex;
        align-items: center;
        justify-content: center;

        pointer-events: none;

        .bottom-tips-qr {
            position: fixed;
            right: 1.5%;
            bottom: 20px;

            img {
                width: 100px;
            }
        }

        .bottom-tips-marks {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .bottom-tips-mark {

                display: flex;
                align-items: center;
                // justify-content: space-between;

                .bottom-tips-mark-image {
                    margin-right: 5px;
                    height: 20px;

                    img {
                        width: 20px;

                        border-radius: 100px;
                        box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.05);
                    }
                }
                
                .bottom-tips-mark-label {
                    margin-right: 15px;

                    font-size: 13px;
                    font-weight: 700;

                    color: #FFFFFF;
                }
            }
        }

        .bottom-tips-maptype {
            width: 55px;
            height: 55px;

            position: fixed;
            right: 1.5%;
            top: 105px;

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            box-sizing: border-box;
            border: 1px solid #FFFFFF;
            border-radius: 12px;

            cursor: pointer;
            pointer-events: all;

            transition: 0.2s;
        }
    }

}