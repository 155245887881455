.window-card {
    width: 100vw;
    height: 100vh;

    position: absolute; 
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;
        
    * {
        flex-direction: row-reverse;
    }

    .window-card-container {
        width: 92%;
        height: 92%;
        max-width: 95%;
        max-height: 95%;

        background-color: rgb(255, 255, 255);
        // backdrop-filter: blur(15px);
        border-radius: 15px;
        border: 3px rgba(255, 255, 255, 0.322) solid;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;

        pointer-events: all;

        position: relative;

        transition: 0.2s;

        .window-card-cross {
            width: 32px;
            height: 32px;
            
            position: absolute;
            z-index: 5;
            top: 12px;
            left: 20px;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: rgba(255, 255, 255, 0.87);
            border-radius: 5px;

            cursor: pointer;
            
            transition: 0.2s;

            img {
                width: 20px;
            }
        }

        .window-card-inner {
            width: calc(100% - 6px);
            height: calc(100% - 6px);

            border-radius: 15px;

            .window-card-gallery {
                width: 100%;
                height: 100%;
                
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                
                border-radius: 10px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                transition: 0.2s;

                .window-card-gallery-arrow {
                    height: 80%;
                    z-index: 2;

                    cursor: pointer;

                    display: flex;
                    align-items: center;

                    user-select: none;

                    img {
                        width: 15px;
                        transition: 0.2s;
                        
                        user-select: none;
                    }
                }

                .window-card-gallery-arrow-left {
                    padding-right: 25%;
                    img {
                        margin-left: 15px;
                    }
                }

                .window-card-gallery-arrow-right {
                    padding-left: 25%;
                    img {
                        margin-right: 15px;
                    }
                }

                .window-card-gallery-back {
                    width: 100%;
                    height: 100%;
                    
                    border-radius: 10px;

                    position: fixed;
                    z-index: 1;
                }

                .window-card-gallery-circles {
                    width: 100%;
                    bottom: 35px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    position: fixed;
                    z-index: 2;
                    
                    .window-card-gallery-circles-inner {
                        display: flex;

                        .window-card-gallery-circle {
                            width: 10px;
                            height: 10px;
                            margin-right: 7px;
    
                            border-radius: 100px;
    
                            transition: 0.2s;
                            cursor: pointer;
    
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .window-card-images {
                overflow-y: auto;

                .window-card-image {
                    img {
                        width: 100%;
                    }
                }
            }

            video {
                border-radius: 15px;
            }

            iframe {
                border-radius: 15px;
            }

            .window-card-tabs {
                width: calc(100% - 6px);
                
                box-sizing: border-box;
                border-radius: 10px 10px 0 0;
                background-color: #F1EDE2;
                
                padding: 3px;
                padding-left: 63px;

                position: absolute;

                .window-card-tabs-inner {
                    max-width: calc(100%);

                    display: flex;
                    overflow-x: auto;

                    .window-card-tab {
                        max-width: 192px;
                        min-width: 125px;
                        width: 100%;
                        height: 44px;

                        background-color: #f4f4f4;
                        border-radius: 8px 8px 0 0 ;

                        display: flex;
                        align-items: center;

                        transition: 0.1s;

                        cursor: pointer;

                        margin-right: 2px;

                        .window-card-tab-text {
                            margin-right: 16px;

                            font-weight: 500;
                            font-size: 13px;

                            color: #000000;

                            transition: 0.1;
                        }
                    }
                    
                    .window-card-tab-active {
                        background-color: rgba(248, 131, 80, 1) !important;

                        .window-card-tab-text {
                            color: #FFFFFF;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}