.main {
    
    .card {
        width: 100vw;
        height: 100vh;

        position: absolute;
        z-index: 9;
        pointer-events: none;

        display: flex;
        align-items: center;

        * {
            
            flex-direction: row-reverse;
        }

        .card-container {
            position: fixed;
            right: 1.5%;
            
            max-width: 380px;
            width: 100%;

            transition: 0.2s;

            background-color: #FFFFFF;
            border-radius: 15px;

            .card-exit {
                position: fixed;

                left: 20px;
                top: 20px;

                cursor: pointer;

                img {
                    width: 17px;

                    transition: 0.2s;
                }
            }

            .card-qr {
                position: absolute;
                right: 6.25%;
                top: 135px;

                display: none;

                img {
                    width: 80px;
                    border-radius: 7.5px;
                }
            }

            .card-top {
                height: 175px;
                border-radius: 15px 15px 0 0;

                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: flex-end;

                background-color: rgb(173, 50, 50);
                transition: 0.2s;
                
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

                .card-top-circles {
                    display: flex;
                    margin-bottom: 20px;

                    position: fixed;

                    .card-top-circle {
                        width: 10px;
                        height: 10px;
                        margin-right: 7px;

                        border-radius: 100px;

                        transition: 0.2s;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .card-top-back {
                    width: 100%;
                    height: 100%;

                    border-radius: 15px 15px 0 0;

                    cursor: nesw-resize;
                }
            }

            .card-bottom {
                max-width: 87.5%;
                width: 100%;

                margin: 0 auto;
                margin-top: 15px;
                margin-bottom: 20px;
                
                .card-title {
                    // max-width: calc(100% - 6.25% - 70px);
                    max-width: calc(100% - 6.25%);
                    margin-bottom: 12px;

                    display: inline-flex;

                    text-decoration: none;
                    color: #000000;

                    .card-title-label {
                        font-size: 23px;
                        font-weight: 700;
                        

                        .card-title-label-image {
                            width: 21px;
                            vertical-align: text-top;
    
                            margin-top: 3px;
                            margin-left: 3px;
                        }
                    }
                }


                .card-desc {
                    font-size: 14px;
                    font-weight: 500;

                    line-height: 140%;
                }

                .card-bottom-buttons {
                    max-width: 100%;
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .card-bottom-button {
                    width: 100%;
                    height: 38px;

                    margin-top: 15px;

                    border-radius: 5px;
                    background-color: rgba(155, 155, 155, 0.205);
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    cursor: pointer;
                    transition: 0.2s;

                    .card-bottom-button-inner {
                        width: 100%;
                        max-width: calc(100% - 30px);
                        
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        font-size: 13.5px;
                        font-weight: 600;

                        user-select: none;

                        img {
                            width: 14px;

                            transition: 0.2s;
                        }
                    }
                }
            }
        }
    }
}